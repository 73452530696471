/*==
 *== Close
 *== ======================================= ==*/

.close {
  float: right;
  opacity: .2;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
  cursor: pointer;
  text-decoration: none;
  opacity: .5;
  color: #000;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
}
