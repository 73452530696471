/*==
 *== Thumbnail
 *== ======================================= ==*/

.thumbnail {
  display: block;
  margin-bottom: 20px;
  padding: 4px;
  -o-transition: border .2s ease-in-out;
  -webkit-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  line-height: 1.42857143;
}

.thumbnail > img,
.thumbnail a > img {
  margin-right: auto;
  margin-left: auto;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #337ab7;
}

.thumbnail .caption {
  padding: 9px;
  color: #333;
}
