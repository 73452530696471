//==
//== Mixins
//== ======================================= ==//


@function grid-use-rem() {
  @return false;
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return "-#{$name}";
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    //noinspection CssInvalidFunction
    $min: if(grid-use-rem(), convert-length($min, rem), $min);
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    //noinspection CssInvalidFunction
    $max: if(grid-use-rem(), convert-length($max, rem), $max);
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);
  $min: if($min == null, 0, $min);
  //noinspection CssInvalidFunction
  $min: if(grid-use-rem(), convert-length($min, rem), $min);
  //noinspection CssInvalidFunction
  $max: if(grid-use-rem(), convert-length($max, rem), $max);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);
  $min: if($min == null, 0, $min);
  //noinspection CssInvalidFunction
  $min: if(grid-use-rem(), convert-length($min, rem), $min);
  //noinspection CssInvalidFunction
  $max: if(grid-use-rem(), convert-length($max, rem), $max);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-gutters($gutters: $grid-gutter-widths, $half: true) {
  @if type_of($gutters) == number {
    $gutters: (xs: $gutters);
  } @else if type_of($gutters) == map {
  } @else {
    @error 'Invalid type of parameter.';
  }

  $prev-gutter: null;
  @each $breakpoint in map-keys($gutters) {
    $gutter: map-get($gutters, $breakpoint);
    @if ($prev-gutter != $gutter) {
      $prev-gutter: $gutter;
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        $value: if($half, $gutter / 2, $gutter);
        $value: if(unit($value) == '', $value * 0px, $value);
        $value: if(grid-use-rem(), convert-length($value, rem), $value);
        padding-right: $value;
        padding-left: $value;
      }
    }
  }
}

@mixin make-margins($gutters: $grid-gutter-widths, $half: true) {
  @if type_of($gutters) == number {
    $gutters: (xs: $gutters);
  } @else if type_of($gutters) == map {
  } @else {
    @error 'Invalid type of parameter.';
  }

  $prev-gutter: null;
  @each $breakpoint in map-keys($gutters) {
    $gutter: map-get($gutters, $breakpoint);
    @if ($prev-gutter != $gutter) {
      $prev-gutter: $gutter;
      @include media-breakpoint-up($breakpoint) {
        $gutter: map-get($gutters, $breakpoint);
        $value: if($half, $gutter / -2, $gutter / -1);
        $value: if(unit($value) == '', $value * 0px, $value);
        $value: if(grid-use-rem(), convert-length($value, rem), $value);
        margin-right: $value;
        margin-left: $value;
      }
    }
  }
}

@mixin make-container($gutters: $grid-gutter-widths) {
  //position: relative;
  margin-left: auto;
  margin-right: auto;
  @include make-gutters($gutters);

  @if ($grid-add-box-sizing) {
    box-sizing: border-box;
  }
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      width: if(grid-use-rem(), convert-length($container-max-width, rem), $container-max-width);
      max-width: 100%;
    }
  }
}

@mixin make-row($gutters: $grid-gutter-widths) {
  @if ($grid-use-flex) {
    display: flex;
    flex-wrap: wrap;
  } @else {
    &::before, &::after {
      content: '';
      display: table;
    }
    &::after {
      clear: both;
    }
  }
  @include make-margins($gutters);
}

@mixin make-col-ready($gutters: $grid-gutter-widths) {
  position: relative;
  width: 100%;

  @if ($grid-use-flex) {
    flex: 0 0 100%;
    max-width: 100%;
  } @else {
    float: left;
    min-height: 1px; // Prevent collapsing
  }

  @if ($grid-add-box-sizing) {
    box-sizing: border-box;
  }

  @include make-gutters($gutters);
}

@mixin make-col($size, $columns: $grid-columns) {
  @if ($grid-use-flex) {
    flex-basis: percentage($size / $columns);
    max-width: percentage($size / $columns);
  } @else {
    width: percentage($size / $columns);
  }
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}

@mixin make-grid-columns($columns: $grid-columns, $gutters: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
  $col: 'col';

  @if ($grid-col-class) {
    $col: $grid-col-class;
  }

  // Common properties for all breakpoints
  %grid-column {
    @include make-col-ready($gutters);
    //float: left;
    ////position: relative;
    //width: 100%;
    //min-height: 1px; // Prevent columns from collapsing when empty
    //
    //@include make-gutters($gutters);
    @content;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @for $i from 1 through $columns {
      .#{$col}#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }

    .#{$col} { /* #{$breakpoint}: #{map_get($breakpoints, $breakpoint)} and up */
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        @for $i from 1 through $columns {
          &#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        $modifiers: [];

        @if ($grid-col-pull-classes) {
          $modifiers: append($modifiers, pull);
        }

        @if ($grid-col-push-classes) {
          $modifiers: append($modifiers, push);
        }

        @each $modifier in $modifiers {
          @for $i from 0 through $columns {
            &#{$infix}-#{$modifier}-#{$i} {
              position: relative;
              @include make-col-modifier($modifier, $i, $columns)
            }
          }
        }

        @if ($grid-col-offset-classes) {
          // `$columns - 1` because offsetting by the width of an entire row isn't possible
          @for $i from 0 through ($columns - 1) {
            @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
              &#{$infix}-offset-#{$i} {
                @include make-col-modifier(offset, $i, $columns)
              }
            }
          }
        }
      }
    }
  }
}

@mixin webkit-scrollbar($color, $background: transparent, $size: 3px) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-button {
    display: none;
    //background: none;
    //size: $size;
    //height: $size;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: $background;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color;

    &:hover {
      background-color: $color;
    }
  }
  &::-webkit-resizer {
    display: none;
    //background: none;
    //size: $size;
    //height: $size;
  }
}

@mixin z-depth($depth: 1) {
  @if ($depth == 1) {
    box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px, rgba(0, 0, 0, 0.117647) 0 1px 4px;
  } @else if ($depth == 2) {
    box-shadow: rgba(0, 0, 0, 0.156863) 0 3px 10px, rgba(0, 0, 0, 0.227451) 0 3px 10px;
  } @else if ($depth == 3) {
    box-shadow: rgba(0, 0, 0, 0.188235) 0 10px 30px, rgba(0, 0, 0, 0.227451) 0 6px 10px;
  } @else if ($depth == 4) {
    box-shadow: rgba(0, 0, 0, 0.247059) 0 14px 45px, rgba(0, 0, 0, 0.219608) 0 10px 18px;
  } @else if ($depth == 5) {
    box-shadow: rgba(0, 0, 0, 0.298039) 0 19px 60px, rgba(0, 0, 0, 0.219608) 0 15px 20px;
  } @else {
    @warn 'Invalid param: $depth (allow in 1..5)';
  }
}

@mixin nn-mark-not-loaded-images($content: "Изображение не загружено (url: " attr(src) ")") {
  img::after {
    content: $content;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    border: 1px solid red;
    background: lightpink;
    text-decoration: none;
    color: #000;
    max-width: 100%;
  }
}
