/*==
 *== Collapse
 *== ======================================= ==*/

.fade {
  -o-transition: opacity .15s linear;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
  opacity: 0;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  overflow: hidden;
  height: 0;
  -o-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -o-transition-duration: .35s;
  -webkit-transition-duration: .35s;
  transition-duration: .35s;
  -o-transition-property: height, visibility;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}
