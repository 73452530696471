//==
//== Settings
//== ======================================= ==//

$z-header: 99800;
$z-lines: 9999;
$z-navmenu: 1;

$mm-width: 250px;
$mm-close-button-height: 48px;
$mm-item-height: 48px;
$mm-background: #d7e5f5;
$mm-color: #337ab7;
$mm-divider: rgba(#000, 0.15);

$mm-breakpoint-toggle: md;

$footer-bg: #4e79a3;

$color-primary-text: #cc6701;

$section-top-padding: 30px;
$section-side-padding: 20px;

$content-gutter-xs: 0;
$content-gutter-sm: 20px;
$content-gutter: 35px;

//==
//== Grid
//== ======================================= ==//

$grid-breakpoints: (
  xs: 0,
  mb: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$container-max-widths: (
  //sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
) !default;

$grid-columns: 12 !default;
$grid-gutter-width-base: 30px !default;
$grid-gutter-widths: (
  xs: 20px,
  mb: 20px,
  sm: 20px,
  md: 20px,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base,
) !default;

$grid-float-breakpoint: map_get($grid-breakpoints, sm) !default;
$grid-float-breakpoint-max: $grid-float-breakpoint - 1;

$grid-container-class: 'container' !default;
$grid-container-fluid-class: 'container-fluid' !default;
$grid-row-class: 'row' !default;
$grid-col-class: 'col' !default;

$grid-col-push-classes: true !default;
$grid-col-pull-classes: true !default;
$grid-col-offset-classes: true !default;

$grid-use-rem: false !default;
$grid-use-flex: true !default;
$grid-add-box-sizing: false !default;

//==
//== Mixins
//== ======================================= ==//

@mixin dd-strip-gradient($strip: #d2d7e2) {
  //background-image: linear-gradient(135deg, $strip 35%, transparent 35%, transparent 50%, $strip 50%, $strip 85%, transparent 85%, transparent);
  background-image: linear-gradient(135deg, $strip 25%, transparent 25%, transparent 50%, $strip 50%, $strip 75%, transparent 75%, transparent);
  background-size: 16px 16px;
  background-position: 50% 0;
}

@mixin dd-strip-gradient2($strip: #d2d7e2) {
  background-image: linear-gradient(135deg, $strip 35%, transparent 35%, transparent 50%, $strip 50%, $strip 85%, transparent 85%, transparent);
  //background-image: linear-gradient(135deg, $strip 25%, transparent 25%, transparent 50%, $strip 50%, $strip 75%, transparent 75%, transparent);
  background-size: 16px 16px;
  background-position: 50% 0;
}
