/*==
 *== List
 *== ======================================= ==*/

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.42857143;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

@include media-breakpoint-up(md) {
  .dl-horizontal dt {
    float: left;
    clear: left;
    overflow: hidden;
    width: 160px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
